body {
    background-color: #D6EAED;
    margin: 0 auto;
    max-width: 80rem;
    font-family: "Inter", sans-serif;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    row-gap: 6rem;
}

.copiedMsg {
    background-color: white;
    padding: 1rem 3rem 2rem 3rem;
    text-align: center;
    border-radius: 1.3rem;
    width: 40%;
    font-size: 20px;
}

.textContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10%;
    margin-top: 1rem;
}

.Container {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 2.5rem;
}

.colorCard {
    background-color: white;
    padding: 2rem 2rem 0.4rem 2rem;
    font-size: 25px;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 10px #c5c4c4;
}

.colors {
    width: 162px;
    height: 162px;
    border-radius: 0.5rem;
}

.btnContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 1rem;
}

button {
    background-color: #5D9DC1;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 1rem 3rem 1rem 3rem;
    cursor: pointer;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 10px #c5c4c4;
    width: 60%;
    align-self: center;
}

button:hover {
    background-color: #497D9A;
}

.text {
    text-align: center;
}

.infoText {
    background-color: white;
    padding: 1rem 3rem 1rem 3rem;
    border-radius: 1.3rem;
}

.copiedMsg {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
  
.copiedMsg.show {
    opacity: 1;
}
  
